import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
  ContentfulComposeSeo,
  ContentfulPageResourcesOverview,
  ResourcesOverviewPageQuery,
} from '@/../graphql-types';
import Layout from '@/components/layout/Layout';
import SEO from '@/components/SEO';
import * as Constants from '@/types/Constants';
import PageResourcesOverview from '@/components/contentful/pages/ResourcesOverview';

export const query = graphql`
  query ResourcesOverviewPage(
    $id: String!
    $node_locale: String!
    $today: Date!
  ) {
    contentfulComponentSiteHeader(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Header" }
    ) {
      ...ContentfulComponentSiteHeaderFragment
    }
    contentfulComponentSiteFooter(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Footer" }
    ) {
      ...ContentfulComponentSiteFooterFragment
    }
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      internal {
        type
      }
      slug
      title
      seo {
        createdAt
        updatedAt
        description
        id
        keywords
        pageType
        no_follow
        no_index
        title
        canonicalUrl
        featuredImage {
          description
          file {
            url
          }
        }
      }
      content {
        ... on ContentfulPageResourcesOverview {
          id
          internal {
            type
          }
          compose__page {
            id
          }
          topic {
            title
            shortDescription {
              shortDescription
            }
          }
          subNav {
            ...ContentfulComponentNavigationFragment
          }
          resourcesSelect {
            ...ContentfulComponentTabbedResourcesFragment
          }
          sections {
            ... on ContentfulComponentEventCardSet {
              ...ContentfulComponentEventCardSetFragment
            }
            ... on ContentfulComponentResourceCardSet {
              ...ContentfulComponentResourceCardSetFragment
            }
          }
          footerCta {
            ...ContentfulComponentFooterCtaFragment
          }
          contactForm {
            ... on ContentfulComponentRegistrationForm {
              ...ContentfulComponentRegistrationFormFragment
            }
          }
          microcopy {
            ... on ContentfulGlobalMicrocopyResource {
              ...ContentfulGlobalMicrocopyResourceFragment
            }
          }
        }
      }
    }
    allContentfulPageCaseStudy(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: {
          elemMatch: {
            disablePage: { eq: false }
            seo: { no_index: { ne: true } }
          }
        }
      }
      sort: { order: DESC, fields: caseStudy___createdAt }
    ) {
      edges {
        node {
          caseStudy {
            id
            updatedAt
            internal {
              type
            }
            title
            subheading
            shortDescription {
              shortDescription
            }
            featureImage {
              gatsbyImageData
            }
            page__case_study {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPageCustomerStory(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
      }
      sort: { order: DESC, fields: customerStory___createdAt }
    ) {
      edges {
        node {
          customerStory {
            id
            internal {
              type
            }
            title
            updatedAt
            subheading
            shortDescription {
              shortDescription
            }
            featureImage {
              gatsbyImageData
            }
            page__customer_story {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPageEvent(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
        eventDetails: { endDate: { gte: $today } }
      }
      sort: { order: ASC, fields: eventDetails___startDate }
    ) {
      edges {
        node {
          eventDetails {
            id
            internal {
              type
            }
            title
            updatedAt
            subheading
            shortDescription {
              shortDescription
            }
            startDate(formatString: "MMM DD, YYYY")
            endDate(formatString: "MMM DD, YYYY")
            featureImage {
              gatsbyImageData
            }
            page__event {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPageWebinar(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
      }
      sort: {
        fields: [webinar___date, webinar___createdAt]
        order: [ASC, DESC]
      }
    ) {
      edges {
        node {
          webinar {
            id
            internal {
              type
            }
            updatedAt
            title
            subheading
            shortDescription {
              shortDescription
            }
            featureImage {
              gatsbyImageData
            }
            page__webinar {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const Page: React.FC<PageProps<ResourcesOverviewPageQuery>> = (props) => {
  const { location, data } = props;
  const pageHeader =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteHeader
      : data?.contentfulComponentSiteHeader;
  const pageFooter =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteFooter
      : data?.contentfulComponentSiteFooter;

  const resourceCollectionSet = { edges: [] };
  const showTabs = true;

  resourceCollectionSet.edges = [
    ...data?.allContentfulPageEvent.edges,
    ...data?.allContentfulPageWebinar.edges,
    ...data?.allContentfulPageCaseStudy.edges,
    ...data?.allContentfulPageCustomerStory.edges,
  ];

  // resourceCollectionSet.edges = resourceCollectionSet.edges.sort((a, b) => {
  //   const updatedAtA = Object.values(a.node)[0].updatedAt;
  //   const updatedAtB = Object.values(b.node)[0].updatedAt;
  //   return new Date(updatedAtB).getTime() - new Date(updatedAtA).getTime();
  // });

  return (
    <>
      <SEO
        location={location}
        defaultPageType={data?.contentfulComposePage?.content?.internal?.type}
        defaultTitle={data?.contentfulComposePage?.title}
        seo={data?.contentfulComposePage?.seo as ContentfulComposeSeo}
      />
      <Layout
        location={location}
        page={data?.contentfulComposePage as ContentfulComposePage}
        header={pageHeader as ContentfulComponentSiteHeader}
        footer={pageFooter as ContentfulComponentSiteFooter}
      >
        <PageResourcesOverview
          data={
            data?.contentfulComposePage
              ?.content as ContentfulPageResourcesOverview
          }
          showTabs={showTabs}
          resourceCollectionSet={resourceCollectionSet}
        />
      </Layout>
    </>
  );
};

export default Page;
