import React from 'react';
import styled from 'styled-components';

import {
  ContentfulComponentBackground,
  ContentfulPageResourcesOverview,
  ContentfulEntry,
  ContentfulComponentTabbedResources,
} from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper'; //helper for background css on other components
import {
  Navigation,
  ResourceIntro,
  EventCardSet,
  ResourceCardSet,
  FooterCta,
  TabbedResources,
  Undefined,
  RegistrationForm,
} from '@/components/contentful/components';
import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

// const url = typeof window !== 'undefined' ? window.location.href : '';
// console.log(url);

interface PageResourcesOverviewProps {
  data?: ContentfulPageResourcesOverview;
  resourceCollectionSet?: Array<any>;
  showTabs?: boolean;
}
const PageResourcesOverview: React.FC<PageResourcesOverviewProps> = (props) => {
  const { data, resourceCollectionSet, showTabs } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="">
      {data?.subNav && (
        <Navigation
          data={data?.subNav}
          currentPage={data.compose__page[0].id}
        />
      )}
      {data?.topic && <ResourceIntro data={data?.topic} />}

      {/* {events && <EventsUpcoming events={events} />}        return <TabbedResources
          data={tabbedResource}
        />; */}
      <TabbedResources
        data={data?.resourcesSelect[0]}
        collection={resourceCollectionSet}
        showTabs={showTabs}
      />
      {/*resourceCollectionSet?.map((resourceCollection: any) => {
        // return <TabbedResources
        //   data={data?.resourcesSelect[0]}
        //   collection={resourceCollection}
        //   showTabs={showTabs}
        // />
      })*/}
      {/* data.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;
        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_EVENT_CARD_SET:
            element = <EventCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RESOURCE_CARD_SET:
            element = <ResourceCardSet key={key} data={contentfulComponent} />;
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }

        return wrapBackground(contentfulComponent, element);
      })*/}

      {data?.footerCta && <FooterCta data={data.footerCta} />}
      {data?.contactForm && <RegistrationForm data={data?.contactForm} />}
    </Container>
  );
};
export default PageResourcesOverview;
